import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"

const NeuerPage = ({ data }) => (
  <Layout>
    {data.allStrapiNeurscheinungen.edges.map(document => (
      <div className="w-full lg:flex mr-5 p-4">
        <div className="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden">
          <Img
            className="hover:grow hover:shadow-lg"
            fluid={document.node.image.childImageSharp.fluid}
            alt={document.node.title}
          />
        </div>
        <div className="bg-white dark:bg-gray-800 p-4 flex flex-col justify-between leading-normal">
          <div className="mb-8">
            <div className="text-joker font-bold text-xl mb-2 dark:text-joker-200">
              {document.node.title}
            </div>
            <p className="text-joker-500 dark:text-joker-200 text-lg font-semibold">
              Autor:{" "}
              <span className="text-purple-700">
                {document.node.autor_name}
              </span>
            </p>
            <p className="text-joker-500 dark:text-joker-200 text-lg font-semibold">
              Erscheint:{" "}
              <span className="text-purple-700">{document.node.erscheint}</span>
            </p>
            <p className="text-joker-500 dark:text-joker-200 text-lg font-semibold">
              Genre:{" "}
              <span className="text-purple-700">{document.node.genre}</span>
            </p>
            <p className="text-joker-500 dark:text-joker-200 text-lg font-semibold">
              Verlag:{" "}
              <span className="text-purple-700">{document.node.verlag}</span>
            </p>
            <p className="text-joker-500 dark:text-joker-200 text-lg font-semibold">
              Preis:{" "}
              <span className="text-purple-700">{document.node.preis}</span>
            </p>
          </div>
          <p>{document.node.content}</p>
        </div>
        <hr />
      </div>
    ))}
  </Layout>
)
export default NeuerPage

export const pageQuery = graphql`
  query NeurPage {
    allStrapiNeurscheinungen {
      edges {
        node {
          title
          autor_name
          erscheint
          genre
          verlag
          preis
          content
          image {
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
